<template>
  <h1 style="text-align: center">西溪谷车位申请</h1>

  <div id="steps-box">
    <a-steps :current="currentStep - 1">
      <a-step
        v-for="step in stateStepList"
        :key="step.title"
        :title="step.title"
        :sub-title="step.subTitle"
        :description="step.description"
      />
    </a-steps>
  </div>

  <div class="steps-content">
    <!-- 第1步 -->
    <div v-show="currentStep === 1">
      <div v-html="stateQaHtml" />
      <div id="read-confirm-btn-box">
        <a-button type="primary" href="dchat://im/start_conversation?name=ljxzzs_public_p">
          我已阅读以上Q&A，前往登记信息
        </a-button>
      </div>
    </div>

    <!-- 第2步 -->
    <div v-show="currentStep === 2">
      <RegisterInfo />
      <div id="read-confirm-btn-box">
        <!-- <div class="btn-group"> -->
        <a-button style="margin-right: 10px" @click="goBack">
          返回上一步
        </a-button>
        <a-button type="primary" @click="onSubmit"> 确认提交申请信息 </a-button>
      </div>
    </div>

    <div v-show="currentStep === 3">
      <a-result
        status="success"
        title="Successfully Purchased Cloud Server ECS!"
        sub-title="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      >
        <div id="read-confirm-btn-box">
          <!-- <div class="btn-group"> -->
          <a-button style="margin-right: 10px" @click="goBack">
            返回上一步
          </a-button>
          <a-button type="primary" @click="nextStep"> 前去缴费 </a-button>
        </div>
      </a-result>
    </div>

    <!-- 第3步 -->
    <div v-show="currentStep === 4">
      <p>停车费结构：租金450元/月+服务费100元/月。（共计550元/月）</p>
      <div>
        <h2>本次费用如下 ：</h2>
        <p>11月2日（起租日）-- 12月8日</p>
        <b>*两笔费用分成两个不同的二维码支付，请不要付反了哦～</b>
      </div>

      <div class="pay-box">
        <div class="cost-box">
          <p>
            第一笔费用553.56元（租金）请转至如图账号（旭天实业）并备注：“G8-10+姓名（本人）+车位租赁”并截图保存上传。
          </p>
          <div class="pic-box">
            <img src="@/assets/parking_fee.png" alt="租金" />
            <div class="upload-box">
              <UploadImage />
            </div>
          </div>
        </div>
        <div class="cost-box">
          <p>
            第二笔费用123.01元（服务费）请转至如图账号（旭月）并备注：“G8-10+姓名（本人）+车位服务费”并截图保存上传。
          </p>
          <div class="pic-box">
            <img src="@/assets/service_charge.png" alt="服务费" />
            <div class="upload-box">
              <UploadImage />
            </div>
          </div>
        </div>
      </div>
      <div id="read-confirm-btn-box">
        <a-button style="margin-right: 10px" @click="goBack">
          返回上一步
        </a-button>
        <a-button type="primary" @click="nextStep">
          确认上传费用缴纳截凭证
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, reactive, ref } from "vue";
import RegisterInfo from "./components/RegisterInfo.vue";
import UploadImage from "./components/UploadImage.vue";
import qaHtml from "@/assets/html/q&a.html.js";

export default {
  name: "App",
  components: {
    RegisterInfo,
    UploadImage,
  },
  setup() {
    const message = inject("$message");
    const stateStepList = reactive([
      {
        title: "车位租赁Q&A",
        subTitle: "",
        description: "滴滴与西溪谷地下协议车位Q&A",
      },
      {
        title: "车位信息登记",
        subTitle: "",
        description: "提交物业审核，审核通过后会提供缴费方式和具体金额给你哦～",
      },
      {
        title: "信息审核结果",
        subTitle: "",
        description: "审核您的车辆是否满足机械车位要求~",
      },
      {
        title: "停车费缴纳",
        subTitle: "",
        description: "停车费结构：租金450元/月+服务费100元/月",
      },
      {
        title: "车位申请结果",
        subTitle: "",
        description: "",
      },
    ]);

    let currentStep = ref(1);
    const stateQaHtml = reactive(qaHtml);

    const nextStep = () => {
      currentStep.value++;
    };
    const goBack = () => {
      currentStep.value--;
    };

    const onSubmit = () => {
      message.info("紧急开发中, 敬请期待!");
      nextStep();
    };

    return {
      currentStep,
      stateStepList,
      stateQaHtml,
      nextStep,
      goBack,
      onSubmit,
    };
  },
};
</script>

<style>
#app {
  color: #2c3e50;
  padding: 30px;
}
#steps-box {
  margin: 30px;
}
hr {
  border-top: 1px solid #eee;
}
#read-confirm-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps-content {
  border: 1px dashed #e9e9e9;
  border-radius: 8px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px;
}
.btn-group {
  float: right;
}
.steps-content::after {
  content: "";
  clear: both;
  display: block;
}
@media screen and (max-width: 480px) {
  .steps-content ul,
  .steps-content ol {
    padding-left: 20px;
  }

  .pay-box {
    flex-direction: column;
  }
}
.pay-box {
  display: flex;
  justify-content: space-between;
}
.cost-box {
  flex: 1;
  padding: 20px;
}
.cost-box img {
  width: 200px;
  height: 200px;
}
.pic-box {
  text-align: center;
}
.upload-box {
  padding: 20px;
  display: flex;
  align-items: center;
}
</style>
<style>
.upload-box .avatar-uploader {
  display: flex;
  justify-content: center;
}
</style>
