<template>
  <a-form :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-item label="姓名">
      <a-input v-model:value="form.name" placeholder="请输入姓名" />
    </a-form-item>
    <a-form-item label="联系方式">
      <a-input v-model:value="form.phone" placeholder="请输入联系方式" />
    </a-form-item>
    <a-form-item label="车牌号">
      <a-input
        v-model:value="form.license_plate_number"
        placeholder="请输入车牌号"
      />
    </a-form-item>
    <a-form-item label="开始停车日期">
      <a-date-picker
        :locale="locale"
        v-model:value="form.start_date"
        @change="onChange"
        :disabled-date="disabledDate"
      />
    </a-form-item>
    <a-form-item label="行驶证内页照片">
      <div class="upload-wrapper">
        <div class="upload-box">
          <a-upload
            v-model:fileList="fileList"
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action=""
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <!-- todo -->
              <loading-outlined v-if="loading" />
              <plus-outlined v-else />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </div>
        <div class="upload-tip">
          <p>左右两页请完整拍在一张照片上</p>
          <p>注：是<b style="color: red">行驶证</b>，不是驾驶证哦～</p>
        </div>
      </div>
    </a-form-item>
    <p>
      📢
      &nbsp;收到上述信息后将先提交物业审核，审核通过了会提供缴费方式和具体金额给你哦～
    </p>
  </a-form>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { reactive } from "vue";

export default {
  name: "RegisterInfo",
  components: { LoadingOutlined, PlusOutlined },
  setup() {
    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    };

    const form = reactive({
      name: "",
      phone: "",
      license_plate_number: "",
      start_date: "",
    });

    return {
      locale,
      disabledDate,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form,
      // 上传照
      fileList: [],
      loading: false,
      imageUrl: "",
    };
  },
};
</script>

<style scoped>
.upload-wrapper {
  display: flex;
}
.upload-box {
  width: 102px;
  height: 102px;
  margin-right: 20px;
}
.upload-tip {
  line-height: 22px;
}
</style>