const html = `<ul>
	<li><span style="font-size:16px">Q：是平面车位&nbsp;or&nbsp;机械车位？</span></li>
	<li><span style="font-size:16px">A：目前滴滴与西溪谷地下协议车位仅有机械车位（无法停放SUV等较大车辆）：&nbsp; &nbsp; &nbsp;<br />
	请确认车身尺寸是否符合机械车位规格要求：</span></li>
</ul>

<ol>
	<li><span style="font-size:16px"><span style="color:#4d80bf">车长：5200mm以下 ，车宽：1900mm以下 ，车高：1550mm以下</span></span></li>
	<li><span style="font-size:16px"><span style="color:#4d80bf">车重：2000KG以下</span></span></li>
	<strong><span style="font-size:16px"><span style="color:#333333"><span style="font-family:&quot;Helvetica Neue&quot;,Helvetica,Arial,&quot;Hiragino Sans GB&quot;,&quot;Hiragino Sans GB W3&quot;,&quot;Microsoft YaHei UI&quot;,&quot;Microsoft YaHei&quot;,&quot;WenQuanYi Micro Hei&quot;,sans-serif"><span style="background-color:#ffffff"><span style="color:#c24f4a">尺寸和车重任何一项超了都无法通过审核，无法停放 !</span></span></span></span></span></strong><br />
	<span style="font-size:16px"><span style="color:#333333"><span style="font-family:&quot;Helvetica Neue&quot;,Helvetica,Arial,&quot;Hiragino Sans GB&quot;,&quot;Hiragino Sans GB W3&quot;,&quot;Microsoft YaHei UI&quot;,&quot;Microsoft YaHei&quot;,&quot;WenQuanYi Micro Hei&quot;,sans-serif"><span style="background-color:#ffffff"><span style="color:#f9963b">机械车位不固定车位，可灵活停放，先到先得。</span>&nbsp;</span></span></span></span>&nbsp;</li>
</ol>

<hr />
<ul>
	<li><span style="font-size:16px">Q：机械车位价格是多少？</span></li>
	<li><span style="font-size:16px">A：优惠价550元/月（物业处原价800元/月）</span><br />
	<span style="font-size:16px"><span style="color:#f9963b">一个机械车位只支持登记一个车牌号。</span></span></li>
</ul>

<hr />
<ul>
	<li><span style="font-size:16px">Q：如何租赁平面车位？</span></li>
	<li><span style="font-size:16px">A：平面车位可停放SUV，请直接在物业处办理，价格为1200元/月（价格可能会有变化，以物业处标价为准）。</span><br />
	<span style="font-size:16px"><span style="color:#f9963b">平面车位固定坐标。</span></span></li>
</ul>

<hr />
<ul>
	<li><span style="font-size:16px">Q：如何租赁？</span></li>
	<li><span style="font-size:16px">A：一个季度起租，即三个月。</span></li>
</ul>

<hr />
<ul>
	<li><span style="font-size:16px">Q：中途可否退租？</span></li>
	<li><span style="font-size:16px">A：车位提前退租业主将自停用日期起算扣除15日车位租金后退还剩余车位租金，车位服务费不退；同学可自行转租并再联系我们办理转租手续。 </span></li>
</ul>

<hr />
<ul>
	<li><span style="font-size:16px">Q：办理后多久生效？</span></li>
	<li><span style="font-size:16px">A：一般情况下<span style="color:#c24f4a"><strong>缴费后</strong></span>生效时间为次日。</span></li>
</ul>

<hr />
<p style="text-align:left"><span style="font-size:16px"><span style="color:#333333"><span style="font-family:&quot;Helvetica Neue&quot;,Helvetica,Arial,&quot;Hiragino Sans GB&quot;,&quot;Hiragino Sans GB W3&quot;,&quot;Microsoft YaHei UI&quot;,&quot;Microsoft YaHei&quot;,&quot;WenQuanYi Micro Hei&quot;,sans-serif"><span style="background-color:#ffffff">温馨提示：租赁机械车位的同学请自觉前往机械车库停放车辆，勿占用地下平面车位 or&nbsp;地面访客临时车位哈～ 感谢大家配合哦～</span></span></span></span></p>
`;

export default html;
